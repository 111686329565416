import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import CardInterface from './model/card/CardInterface';
import CardService from './services/CardService';
import Card from './model/card/Card';
import FileBoxComponent from './view/FileBoxComponent';
import FileInterface from './model/file/FileInterface';
import Lottie from "lottie-react";
import Loader from "./loader.json";
import Modal from 'react-modal';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import PreviewCarroussel, { PreviewCarrousselHandler } from './view/PreviewCarroussel/PreviewCarroussel';

function App() {

  const [card, setCard] = useState<CardInterface>({} as Card);
  const [files, setFiles] = useState<FileInterface[]>([]);
  const [cardID, setCardID] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const [modalIsOpen, setOpen] = useState<boolean>(false);
  const [IsDownloadFile, setIsDownloadFile] = useState<boolean>(false);
  const [locationHref] = useState<string>(window.location.href);
  const previewRef = useRef<PreviewCarrousselHandler>(null)
  const [isShowContactInfo, setShowContactInfo] = useState<boolean>(false);


  const saveContact = () => {
    CardService.saveContact(cardID);
  }

  const fetchCard = (cardUrl: string) => {
    CardService.fetchCard(cardUrl).then(cardData => {
      setCard(cardData);
      fetchFiles(cardUrl);
      setLoading(false);
    });

  }
  const copyToClipBoard = () => {
    CardService.copyToClipBoard(locationHref);
    setOpen(false)
  }

  const sharePage = () => {
    setOpen(true);
  }

  const fetchFiles = (cardUrl: string) => {
    if (!files.length) {
      CardService.getFiles(cardUrl).then(files => {
        setFiles(files);
      });

    }
  }

  const closeModal = () => {
    setOpen(false);
  }

  const downloadsFiles = () => {
    CardService.downloadsFiles(files);
  }

  function getQueryVariable(variable: string): string {
    let query = window.location.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === variable) { return pair[1]; }
    }
    return "";
  }

  useEffect(() => {
    document.title = 'Liberson Card';
    let id = getQueryVariable("q");
    if (id !== "") {
      setCardID(id);
    }
    if (card.url === undefined && cardID && cardID !== '') {
      fetchCard(cardID);
    }

  }, [cardID, card, files])

  const showFiles = () => {
    if (previewRef.current) {
      previewRef.current.preview(0, true)
    }
  }

  const preview = (idx: number) => {
    if (previewRef.current) {
      previewRef.current.preview(idx)
    }
  }


  let shareMessage = `My Card`;
  if (card.name) {
    shareMessage = `${card.name.trim()}'s Card`;
  }


  return (
    <div className="App">

      {isLoading && <Lottie className="loader" animationData={Loader} loop={true} />}

      {!isLoading &&
        <>
          <div >
            <header className="App-header">
              <div className="content-width">
                {/* 
                <div className="textHeader">
                  <h5 className="smartBusineessText">Smart Business Card</h5>
                  <img style={{ display: 'inline-block' }} src="air_plane.svg" alt="air plane icon" />
                </div>
                <a href="#" className="btngetCard" onClick={getCard}>Get Your Card</a>
                */}
              </div>
            </header>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <div className="content-width">

                <div className="cardProfile">

                  {card.image && card.image.trim() !== "" &&
                    <div style={{ width: '40%', display: 'flex' }} >
                      <img className="imageProfile" src={card.image} />
                    </div>
                  }
                  {(!card.image || card.image.trim() === "") &&
                    <div style={{ width: '40%' }} >
                      <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
                        <img src="Img_load_box_fill.svg" width={'40%'} />
                      </div>
                    </div>
                  }


                  <div className="cardText">
                    <div className="profileText pincipalText">{card.jobDetail}</div>
                    <div className="profileText subtitle">{card.name}</div>
                    <div className="profileText subtitle">{card.job}</div>
                  </div>
                </div>

                <div className="btnDiv">
                  {isShowContactInfo && <div style={{marginTop: '5%'}}>
                    {card.phone && card.phone.toString().trim() !== "" &&
                      <div className="divInfo"><img className="btnDivIcon" src="Phone.svg" alt="Phone icon" /> <p className="phone"><a href={'tel:'+card.phone}>{card.phone}</a></p></div>
                    }
                    {card.email && card.email.trim() !== "" &&
                      <div className="divInfo"><img className="btnDivIcon" src="Email.svg" alt="email icon" /> <p className="email">{card.email}</p></div>
                    }
                    {card.address && card.address.trim() !== "" &&
                      <div className="divInfo"> <p className="address"><img className="btnDivIcon" src="Map.svg" alt="Globe icon" style={{ paddingTop: '1%' }} />{card.address}</p></div>
                    }
                    {/*{card.url && card.url.trim() !== "" &&
                      <div className="divInfo"> <a href={card.url} className="url"><img className="btnDivIcon" src="Link.svg" alt="Link icon" style={{ paddingTop: '0%' }} />{card.urlWebsite}</a></div>
                    }*/}
                  </div>}
                   <p style={{marginTop: '3%', marginBottom: '0' }} onClick={() => setShowContactInfo(!isShowContactInfo)} >
                      {isShowContactInfo ? 
                        <div style={{display: 'flex', justifyContent: 'center'}}>  <p className="hideText">Hide Info</p> <img className="btnDivIcon" src="hide_up.svg" alt="hide icon" /></div> :
                        <div style={{display: 'flex', justifyContent: 'center'}}>  <p className="hideText">Show Info</p> <img style={{transform: 'rotate(180deg)'}} className="btnDivIcon" src="hide_up.svg" alt="show icon" /></div> }
                   </p>     
                </div>
                <div className="btnActions">
                  {files && files.length > 0 && <a href="#" onClick={saveContact} style={{display: 'flex', justifyContent: 'center'}} className="btnPlayFiles"> <img className="btnDivIcon" src="Save_Contact_blue.svg" alt="Link icon" style={{ paddingTop: '0%' }} /> <span className="textActionsWhite">Save Contact</span></a>}
                  {/*{card.urlWebsite && card.urlWebsite.trim() !== "" && <a href={card.url} className="btnGoToWebSite" target={'_blank'} >My Web Site</a>}*/}
                  <div className="divShareandSave" style={{display: card.url ? 'flex' : 'block'}}>
                    { card.url && card.url.trim() !== "" && 
                       <a href={card.url} className="btnSaveContact" style={{display: 'flex', justifyContent: 'center'}} > <img className="btnDivIcon" src="link_light_blue.svg" alt="Link icon" style={{ paddingTop: '0%' }} /> <span className="textActions">Website</span></a>
                     }
                    <a href="#" className="btnSharePage" style={{display: card.url ? 'flex' : 'block', justifyContent: 'center', width: card.url ? '49%' : '100%', marginLeft: card.url ? '2%' : '0%'}} onClick={sharePage}> <img className="btnDivIcon" src="Share_blue.svg" alt="Share icon" style={{ paddingTop: '0%' }} /> <span className="textActions">Share</span></a>
                  </div>
                  {IsDownloadFile && <a href="#" className="btnDownloadFiles" onClick={downloadsFiles}>Download files</a>}
                </div>

                <div style={{ marginTop: '2%', marginBottom: '2%', textAlign: 'left', width: '100%' }}>
                  <p className="filesText">Attachment</p>
                  <div className="fileDiv">
                    {files.length > 0 && (files).map((file, index) => {
                      return <FileBoxComponent file={file} index={index} key={index} preview={preview} />;
                    })}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>}

      <PreviewCarroussel ref={previewRef} files={files} />

      <Modal
        isOpen={modalIsOpen}
        className="modalShare"
        ariaHideApp={false}
        onRequestClose={closeModal}
      >
        <div className="modalShareContent">
          <div className="divShareText"><p className="textShare">Share</p> <img className="closeModal" src="close.svg" onClick={closeModal} alt="Close icon" /></div>

          <div className="divShareIcon">
            <FacebookShareButton
              style={{ padding: '2%' }}
              url={locationHref}
              quote={shareMessage}
            >
              <FacebookIcon size={50} round />
              <p className="text">Facebook</p>
            </FacebookShareButton>

            <TwitterShareButton
              style={{ padding: '2%' }}
              url={locationHref}
              title={shareMessage}
            >
              <TwitterIcon size={50} round />
              <p className="text">Twitter</p>
            </TwitterShareButton>

            <TelegramShareButton
              style={{ padding: '2%' }}
              url={locationHref}
              title={shareMessage}
            >
              <TelegramIcon size={50} round />
              <p className="text">Telegram</p>
            </TelegramShareButton>

            <WhatsappShareButton
              style={{ padding: '2%' }}
              url={locationHref}
              title={shareMessage}
              separator=": "
            >
              <WhatsappIcon size={50} round />
              <p className="text">Whatsapp</p>
            </WhatsappShareButton>

            <LinkedinShareButton url={locationHref} style={{ padding: '2%' }} title={shareMessage}>

              <LinkedinIcon size={50} round />
              <p className="text">Linkedin</p>
            </LinkedinShareButton>

            <button className="react-share__ShareButton copyButton" onClick={copyToClipBoard}>
              <img src="copy.svg" onClick={copyToClipBoard} alt="copy icon" style={{ margin: '0', marginLeft: '2%', marginBottom: '2%', width: '30px', height: '35px' }} />
              <p className="text">Copy Link</p>
            </button>
          </div>

        </div>

      </Modal>



    </div >
  );
}

export default App;
