import CardInterface from '../model/card/CardInterface';
import FileInterface from '../model/file/FileInterface';
import axios from 'axios';
import ApiMapperInterface from '../model/fileApiMapper/ApiMapperInterface';
import Card from '../model/card/Card';


export default class CardGateway {

	static pathApi : string = "https://objectbus.liberson.app/busobjects";

	static fetchCard (id: string) : Promise<ApiMapperInterface> {
		let urlpath = CardGateway.pathApi + `/${id}`;
		return axios.get(urlpath);
		// return new Promise(resolve => resolve({} as ApiMapperInterface));
		// return new Promise( resolve =>  resolve({ data: new Card('url', 'Candice Watkins', 'Computer Tech', 'Working on Google', 'London, UK', 12345679810, 'teste@email.com', '247 W. Bonita Ave San Dimas, CA 91773', 'woman.jpeg', '') } ) );
	}

	static getFiles(id: string) : Promise<ApiMapperInterface> {
		let urlpath = CardGateway.pathApi + `/${id}/attachments`;
		return axios.get(urlpath, {});
		// return new Promise( resolve =>  resolve([new File("https://www.youtube.com/watch?v=ki2M68Hu_EI", "Video1", FileTypesEnum.Movie, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.youtube.com/watch?v=ki2M68Hu_EI", "Video2", FileTypesEnum.Movie, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("123", "Arquivo1", FileTypesEnum.Other, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Arquivo2", FileTypesEnum.Other, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Arquivo3", FileTypesEnum.Other, true, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("321", "Arquivo3", FileTypesEnum.Other, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Audio1", FileTypesEnum.Audio, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Audio2", FileTypesEnum.Audio, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Audio", FileTypesEnum.Audio, false, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("312", "Documento1", FileTypesEnum.Doc, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Documento2", FileTypesEnum.Doc, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Documento3", FileTypesEnum.Doc, false, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("312", "Pdf1", FileTypesEnum.Pdf, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Pdf2", FileTypesEnum.Pdf, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Pdf3", FileTypesEnum.Pdf, true, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("312", "MP31", FileTypesEnum.MP3, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "MP32", FileTypesEnum.MP3, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "MP33", FileTypesEnum.MP3, false, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("https://www.vivernatural.com.br/wp-content/uploads/2018/04/3-min.jpg", "Image1", FileTypesEnum.Image, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.vivernatural.com.br/wp-content/uploads/2018/04/3-min.jpg", "Image2", FileTypesEnum.Image, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.vivernatural.com.br/wp-content/uploads/2018/04/3-min.jpg", "Image3", FileTypesEnum.Image, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.youtube.com/watch?v=ki2M68Hu_EI", "Video3", FileTypesEnum.Movie, false, "https://br.pinterest.com/pin/431360470562313621/")])  );

	}

	static goToWebsite (card : CardInterface) : void{
		//Redirect
	}

	static getCard() : void {
		//Get card 🧙🏽‍♂️
	}


	static downloadsFiles(files : FileInterface[]) : void{
		//Downloads Files
	}

}
