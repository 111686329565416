import { VCARD } from "../card";
import { EmailProperty, FNProperty, NicknameProperty, NProperty, OrgProperty, RoleProperty, TelProperty, TitleProperty, URLProperty } from "../properties/index";
import { SpecialValueType } from "../values/SpecialValueType";
import { TextType } from "../values/TextType";
import { URIType } from "../values/URIType";
import { NPropertyValues, ValueOrTypedProperty, ValueOrTypedPropertyStructured, VCardValues } from "./Vcard";
import { NPropertyBuilder } from "./NPropertyBuilder";
import { BaseBuilder } from "./BaseBuilder";
import { AdrPropertyBuilder } from "./AdrPropertyBuilder";

export class VCardBuilder extends BaseBuilder {
    private properties: any[]

    constructor(vCardValues?: VCardValues) {
        super();
        this.properties = []
        if (vCardValues) {
            this.from(vCardValues)
        }
    }

    /**
     * Construct from value object
     * @param vCardValues 
     */
    from(vCardValues: VCardValues) {
        if (vCardValues.fn) {
            this.fn(vCardValues.fn)
        }

        if (vCardValues.adr) {

            this.withAdr().from(vCardValues.adr).buildAdrProperty()
        }

        if (vCardValues.email) {
            this.email(vCardValues.email)
        }

        if (vCardValues.n) {
            this.withN().from(vCardValues.n).buildNProperty()
        }

        if (vCardValues.nickname) {
            this.nickname(vCardValues.nickname)
        }

        if (vCardValues.phone) {
            this.tel(vCardValues.phone)
        }

        if(vCardValues.url){
            this.url(vCardValues.url);
        }

        return this
    }

    /**
     * Add raw property
     * @param property 
     */
    addPropert(property: any) {
        this.properties.push(property)
    }

    /**
     * To specify the formatted text corresponding to the name of the object the vCard represents.
     * @param textValue A single text value.
     * @returns 
     */
    fn(textValue: string) {
        this.properties.push(new FNProperty(
            [],
            new TextType(textValue)
        ))
        return this
    }


    /**
     * Change build context to NPropertyBuilder
     * @returns a NPropertyBuilder to constuct NProperty
     */
    withN() {
        return new NPropertyBuilder(this)
    }

    /**
     *  To specify the components of the name of the object the vCard represents.
     * @param nPropertyValues Represents a single structured text value.  Each component can have multiple values.
     * @returns 
     */
    n(nPropertyValues?: NPropertyValues) {
        new NPropertyBuilder(this, nPropertyValues).buildNProperty()
    }

    nickname(nicknames: ValueOrTypedPropertyStructured): VCardBuilder {
        const nicks = Array.isArray(nicknames) ? nicknames : [nicknames]

        nicks.forEach(n => {
            this.properties.push(
                new NicknameProperty(
                    this.makeTypeParameter(n, 'nicknameproperty'),
                    new TextType(this.getValue(n))
                ))
        })

        return this
    }



    /**
     * To specify the organizational name and units associated with the vCard.
     * @param organizationNameWithLevels A single structured text value consisting of components.
     * @returns 
     */
    org(organizationNameWithLevels: string | string[]) {

        const levels = Array.isArray(organizationNameWithLevels) ? organizationNameWithLevels : [organizationNameWithLevels]

        this.properties.push(
            new OrgProperty(
                [],
                new SpecialValueType(
                    levels.map(l => new TextType(l)),
                    'orgproperty'
                )
            ))
        return this
    }

    /**
     * To specify the position or job of the object the vCard represents.
     * @param title A single text value.
     * @returns 
     */
    title(title: string) {
        this.properties.push(
            new TitleProperty(
                [],
                new TextType(title)
            ))
        return this
    }

    /**
    * To specify the function or part played in a particular situation by the object the vCard represents.
    * @param roles A single text value or array of text ( Cardinality:  *) 
    * @returns 
    */
    role(roles: ValueOrTypedPropertyStructured): VCardBuilder {
        const rolesArray = Array.isArray(roles) ? roles : [roles]

        rolesArray.forEach(r => {
            this.properties.push(
                new RoleProperty(
                    this.makeTypeParameter(r, 'roleproperty'),
                    new TextType(this.getValue(r))
                ))
        })

        return this
    }

    /**
    * To specify a uniform resource locator associated with the 
    * object to which the vCard refers.  Examples for individuals
    * include personal web sites, blogs, and social networking site
    * identifiers.
    *
    * @param urls  A single uri value or array of uris ( Cardinality:  *) 
    * @returns chained vcard builder
    */
    url(urls: ValueOrTypedPropertyStructured): VCardBuilder {
        const urlsArray = Array.isArray(urls) ? urls : [urls]

        urlsArray.forEach(u => {
            this.properties.push(
                new URLProperty(
                    this.makeTypeParameter(u, 'urlproperty'),
                    new URIType(this.getValue(u))
                ))
        })

        return this
    }

    /**
     * Change build context to AdrPropertyBuilder to specify the components of the delivery address for the vCard object.
     * @returns a AdrPropertyBuilder to constuct AdrProperty
     */
    withAdr() {
        return new AdrPropertyBuilder(this)
    }


    /**
     * Specify the telephone number for telephony communication with the object the vCard represents.
     * @param phone 
     */
    tel(phone: string) {
        this.properties.push(
            new TelProperty(
                [],
                new TextType(phone)
            ))
        return this
    }

    /**
     * Specify the telephone number for telephony communication with the object the vCard represents.
     * @param phone 
     */
    email(email: string) {
        this.properties.push(
            new EmailProperty(
                [],
                new TextType(email)
            ))
        return this
    }

    /**
     * Get a vCard v4 in VCF text representation
     * @returns 
     */
    buildVcf(): string {
        const vc = new VCARD(this.properties);
        return vc.repr();
    }

    /**
     * Get a vCard v4 turtle representation
     * @returns 
     */
     buildTurtle(identifier : string): string {
        const vc = new VCARD(this.properties);
        return vc.reprTurtle(identifier);
    }


}