import Card from '../model/card/Card';
import CardInterface from '../model/card/CardInterface';
import FileInterface from '../model/file/FileInterface';
import File from '../model/file/File';
import { FileTypesEnum } from '../model/file/FileTypesEnum';
import CardGateway from '../gateway/CardGateway'
import { VCardBuilder } from "../vcard/buider/VCardBuilder";
import CardBuilderVcf from "../model/fileApiMapper/CardBuilderVcf";


export default class CardService {


	static fetchCard (cardID: string): Promise<CardInterface> {
		return CardGateway.fetchCard(cardID).then(cardData => {
			return new Promise(resolve => {
				resolve(CardService.toCardObject(cardData));

			});
		});

		// return new Promise( resolve =>  resolve(new Card('url', 'Candice Watkins', 'Computer Tech', 'Working on Google', 'London, UK', 12345679810, 'teste@email.com', '247 W. Bonita Ave San Dimas, CA 91773', 'woman.jpeg', '')) );
	}


	static getFiles (cardID: string): Promise<FileInterface[]> {
		return CardGateway.getFiles(cardID).then(filesData => {
			return new Promise(resolve => {
				if (Object.keys(filesData).length > 0) {
					let itens = filesData.data !== undefined && filesData.data.itens !== undefined ? filesData.data.itens : [];
					resolve(itens.map((f: any) => CardService.toFileObject(f)));
				}

			});
		});

		// return new Promise( resolve =>  resolve([new File("https://www.youtube.com/watch?v=ki2M68Hu_EI", "Video1", FileTypesEnum.Movie, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.youtube.com/watch?v=ki2M68Hu_EI", "Video2", FileTypesEnum.Movie, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("123", "Arquivo1", FileTypesEnum.Other, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Arquivo2", FileTypesEnum.Other, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Arquivo3", FileTypesEnum.Other, true, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("321", "Arquivo3", FileTypesEnum.Other, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Audio1", FileTypesEnum.Audio, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Audio2", FileTypesEnum.Audio, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Audio", FileTypesEnum.Audio, false, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("312", "Documento1", FileTypesEnum.Doc, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Documento2", FileTypesEnum.Doc, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Documento3", FileTypesEnum.Doc, false, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("312", "Pdf1", FileTypesEnum.Pdf, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "Pdf2", FileTypesEnum.Pdf, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "Pdf3", FileTypesEnum.Pdf, true, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("312", "MP31", FileTypesEnum.MP3, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("321", "MP32", FileTypesEnum.MP3, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("312", "MP33", FileTypesEnum.MP3, false, "https://br.pinterest.com/pin/431360470562313621/"),
		// new File("https://www.vivernatural.com.br/wp-content/uploads/2018/04/3-min.jpg", "Image1", FileTypesEnum.Image, false, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.vivernatural.com.br/wp-content/uploads/2018/04/3-min.jpg", "Image2", FileTypesEnum.Image, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.vivernatural.com.br/wp-content/uploads/2018/04/3-min.jpg", "Image3", FileTypesEnum.Image, true, "https://br.pinterest.com/pin/431360470562313621/"), 
		// new File("https://www.youtube.com/watch?v=ki2M68Hu_EI", "Video3", FileTypesEnum.Movie, false, "https://br.pinterest.com/pin/431360470562313621/")])  );

	}


	static goToWebsite (card: CardInterface): void {
		CardGateway.goToWebsite(card);
	}

	static getCard (): void {
		//Get card 🧙🏽‍♂️ 
	}


	static saveContact (cardID: string): void {
		CardGateway.fetchCard(cardID).then(cardData => {
			
			const link = document.createElement('a');
			let builder = new VCardBuilder()
			let card = cardData.data;
			let urls = [{"type": "home", "value": window.location.href}, {"type": "home", "value": card.url}];
			card.url = urls;
			let resultBuilderCard = builder.from(new CardBuilderVcf(card)).buildVcf();
			link.href = `data:text/vcard;charset=utf-8,${encodeURIComponent(resultBuilderCard)}`;
			link.setAttribute(
				'download',
				`card.vcf`,
			);
			document.body.appendChild(link);
			link.click();
			if (link.parentNode != null) {
				link.parentNode.removeChild(link);
			}
		})



	}

	static downloadsFiles (files: FileInterface[]): void {
		CardGateway.downloadsFiles(files);
	}

	static toCardObject (cardData: any): CardInterface {

		let url: string = cardData?.data['url'] !== undefined ? cardData.data['url'] : "";
		if (url !== "" && !url.toLocaleLowerCase().startsWith("http:") && !url.toLocaleLowerCase().startsWith("https")) {
			url = "http://" + url
		}
		let name = cardData.data?.fn !== undefined ? cardData.data?.fn : "";
		let job = cardData.data?.title !== undefined ? cardData.data?.title : "";
		let jobDetail = cardData?.data['organization-name'] !== undefined ? cardData.data['organization-name'] : "";
		let location = cardData.data?.adr ? `${cardData.data?.adr?.locality}, ${cardData.data?.adr['country-name']}` : "";
		let phone = cardData.data?.hasTelephone?.hasValue !== undefined ? cardData.data?.hasTelephone?.hasValue?.split(':')[1] : "";
		let email = cardData.data?.hasEmail !== undefined ? cardData.data?.hasEmail?.split(":")[1] : "";

		const addressArr: string[] = []

		if (cardData.data?.adr != undefined) {
			if (cardData.data?.adr['street-address'] !== undefined) {
				addressArr.push(cardData.data?.adr['street-address'])
			}
			if (cardData.data?.adr['locality'] !== undefined) {
				addressArr.push(cardData.data?.adr['locality'])
			}
			if (cardData.data?.adr['region'] !== undefined) {
				addressArr.push(cardData.data?.adr['region'])
			}
			if (cardData.data?.adr['country-name'] !== undefined) {
				addressArr.push(cardData.data?.adr['country-name'])
			}
			if (cardData.data?.adr['postal-code'] !== undefined) {
				addressArr.push(cardData.data?.adr['postal-code'])
			}
		}

		let address = addressArr.length > 0 ? addressArr.join(", ") : "";
		let image = cardData.data?.image !== undefined && cardData.data?.image?.href !== undefined ? cardData.data.image.href : "";
		let urlWebsite = cardData.data?.url !== undefined ? cardData.data?.url : ""

		return new Card(url, name, job, jobDetail, location, phone, email, address, image, urlWebsite);
	}

	static copyToClipBoard (urlCard: string) {
		let textField = document.createElement('textarea')
		textField.innerText = urlCard
		document.body.appendChild(textField)
		textField.select()
		document.execCommand('copy')
		textField.remove()
	}


	static toFileObject (fileObject: any): FileInterface {

		let source = fileObject.id !== undefined ? fileObject.id : "";
		let name = fileObject.name !== undefined ? fileObject.name : "";
		let type = CardService.extractTypeFile(fileObject.url.mediaType !== undefined ? fileObject.url.mediaType : "");
		let url = fileObject.url !== undefined && fileObject.url.href !== undefined ? fileObject.url.href : "";

		return new File(source, name, type, true, url);
	}

	static openFile (cardUrl: string) {
		const newWindow = window.open(cardUrl, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}


	static extractTypeFile (type: string): FileTypesEnum {
		if (type.toLocaleLowerCase().startsWith("audio/")) {
			return FileTypesEnum.Audio;
		}
		if (type.toLocaleLowerCase().startsWith("image/")) {
			return FileTypesEnum.Image;
		}
		if (type.toLocaleLowerCase().startsWith("video/")) {
			return FileTypesEnum.Movie;
		}
		if (type.toLocaleLowerCase().startsWith("application/pdf")) {
			return FileTypesEnum.Pdf;
		}
		if (type.toLocaleLowerCase().startsWith("application/msword")) {
			return FileTypesEnum.Doc;
		}
		return FileTypesEnum.Other;

	}



}