import { BaseValue } from './BaseValue.js';
import { MissingArgument } from '../errors/index.js';

export class TextType extends BaseValue {
  static type = 'TEXT';
  static identifier = 'TextType';

  #textValue;

  #validate(textValue) {
    if (typeof textValue === 'undefined')
    throw new MissingArgument('Value for TextType must be supplied');

    else if (typeof textValue !== 'string')
    throw new TypeError('Only type string allowed for TextType value');
  }

  #cleanUp(textValue) {
    return textValue.replace(/\\/gm, '\\\\').replace(/,/gm, '\\,').replace(/:/gm, '\\:').replace(/;/gm, '\\;').replace(/\n/gm, '\\n');
  }

  #cleanUpXML(textValue) {
    return textValue.replace(/&/gm, '&amp;').replace(/>/gm, '&gt;').replace(/</gm, '&lt;').replace(/"/gm, '&quot;').replace(/'/gm, '&apos;');
  }

  get value() {
    return this.#cleanUp(this.#textValue);
  }

  get valueXML() {
    return `<${this.constructor.type.toLowerCase()}>${this.#cleanUpXML(this.#textValue)}</${this.constructor.type.toLowerCase()}>`;
  }

  get valueJSON() {
    return [ this.constructor.type.toLowerCase(), this.#textValue ];
  }

  get _unsafe_raw_value() {
    return this.#textValue;
  }

  constructor(textValue) {
    super();
    this.#validate(textValue);
    this.#textValue = textValue;

    this.checkAbstractPropertiesAndMethods();
    Object.freeze(this);
  }
}

Object.freeze(TextType);
