import React from 'react';
import FileInterface from '../model/file/FileInterface';
import { FileTypesEnum } from '../model/file/FileTypesEnum';

interface FileBoxComponentProps {
  file: FileInterface,
  index: number,
  preview: (idx: number) => void
}


function FileBoxComponent({ file, index, preview }: FileBoxComponentProps) {

  /*
  const openFile = () => {
    CardService.openFile(file.url);
  }*/

  const marginLeft = (index + 2) % 2 === 0 ? '0%' : '2%'

  return (
    <div className="boxFile" style={{ marginLeft: marginLeft }} onClick={() => { preview(index) }}>
      {
        file.type === FileTypesEnum.Doc &&
        <>
          <img alt='Document' className="iconPrimary" src="microsoft-word.svg" />
          <p className="text text-filebox">{decodeURI(file.name)}</p>
        </>
      }
      {
        file.type === FileTypesEnum.Pdf &&
        <>
          <img alt='PDF' className="iconPrimary" src="pdf.svg" />
          <p className="text text-filebox">{decodeURI(file.name)}</p>
        </>
      }
      {
        file.type === FileTypesEnum.MP3 &&
        <>
          <img src="mp3.svg" className="iconPrimary" alt="MP3 icon" />
          <p className="text text-filebox">{decodeURI(file.name)}</p>
        </>
      }
      {
        file.type === FileTypesEnum.Other &&
        <>
          <img alt='Other file type' className="iconPrimary" src="other-file.svg" />
          {<div className="text text-filebox">{decodeURI(file.name)}</div>}
        </>
      }
      {
        file.type === FileTypesEnum.Image &&
        <div className='img-container'>
          <img
            alt='ImagePhoto'
            className="image-cover"
            src={file.url}
          />
        </div>
      }
      {
        file.type === FileTypesEnum.Audio &&
        <>
          <img alt='Audio' className="iconPrimary" src="AudioIcon.svg" />
          <p className="text text-filebox">{file.name}</p>
        </>
      }
      {
        file.type === FileTypesEnum.Movie &&
        <>
          <img src="player.svg" className="iconPrimary" alt="Video icon" />
          <p className="text text-filebox">{file.name}</p>
        </>
      }
    </div >
  )




}


export default FileBoxComponent;