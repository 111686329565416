import CardInterface from './CardInterface';


export default class Card implements CardInterface{

	url = "";
    name = "";
    job = "";
    jobDetail = "";
    location = "";
    phone =  0;
    email = "";
    address = "";
    image = "";
    urlWebsite = "";

	constructor(url: string, name: string, job: string, jobDetail: string, location: string, phone: number, email: string, address: string, image: string, urlWebsite: string){
		this.url = url;
		this.name = name;
		this.job = job;
		this.jobDetail = jobDetail;
		this.location = location;
		this.phone = phone;
		this.email = email;
		this.address = address;
		this.image = image;
		this.urlWebsite = urlWebsite;
	} 




	
}