import { BaseProperty } from './BaseProperty.js';
import { MissingArgument, InvalidArgument } from '../errors/index.js';

export class AdrProperty extends BaseProperty {
  static identifier = 'AdrProperty';
  static prop = 'ADR';
  static cardinality = '*';
  static acceptableParamTypes = new Set([
    'LabelParameter',
    'ValueParameter',
    'LanguageParameter',
    'GeoParameter',
    'TzParameter',
    'AltidParameter',
    'PIDParameter',
    'PrefParameter',
    'IndexParameter',
    'TypeParameter',
    'AnyParameter',
    'CCParameter'
  ]);
  static acceptableValTypes = 'SpecialValueType';

  #params;
  #value;

  get params() {
    return this.#params.reduce((parametersArray, currentParameter) => {
      parametersArray.push(currentParameter.repr());
      return parametersArray;
    }, []).join(';');
  }

  get paramsXML() {
    return this.#params.reduce((accumulatedParameters, currentParameter) => accumulatedParameters + currentParameter.reprXML(), '');
  }

  get paramsJSON() {
    return this.#params.reduce(
      (accumulatedParameters, currentParameter) => ({ ...currentParameter.reprJSON(), ...accumulatedParameters }),
      {}
    );
  }

  get value() {
    return this.#value.repr();
  }

  get valueXML() {
    return this.#value.reprXML();
  }

  get valueJSON() {
    return this.#value.reprJSON();
  }

  reprTurtle() {
    let componentsTurtle = []
    let hasAddress = false
    const adrComponents = this.valueJSON[1]

    for (let i = 0; i < adrComponents.length; i++) {
      const component = adrComponents[i]
      hasAddress = hasAddress || (component && component !== '')
      switch (i) {
        case 0:
          if (component !== '') {
            componentsTurtle.push(`v:post-office-box "${component}"`)
          }
          break;
        case 1:
          if (component !== '') {
            componentsTurtle.push(`v:extended-address "${component}"`)
          }
          break;
        case 2:
          if (component !== '') {
            componentsTurtle.push(`v:street-address "${component}"`)
          }
          break;
        case 3:
          if (component !== '') {
            componentsTurtle.push(`v:locality "${component}"`)
          }
          break;
        case 4:
          if (component !== '') {
            componentsTurtle.push(`v:region "${component}"`)
          }
          break;
        case 5:
          if (component !== '') {
            componentsTurtle.push(`v:postal-code "${component}"`)
          }
          break;

        case 6:
          if (component !== '') {
            componentsTurtle.push(`v:country-name "${component}"`)
          }
          break;

        default:
          break;
      }
    }

    if (hasAddress) {
      let valueTurtle = 'v:adr [\n';
      valueTurtle += componentsTurtle.join(';\n')
      valueTurtle += '\n]';
      return valueTurtle;
    }
    return ''

  }

  #validate(params, value) {
    if (typeof params === 'undefined' || typeof value === 'undefined')
      throw new MissingArgument('Parameters and value for AdrProperty must be supplied');

    else if (!Array.isArray(params))
      throw new InvalidArgument('Parameters for AdrProperty must be passed in an array');

    const parameterInstanceCount = new Set();

    if (
      !params.every(param => {
        if (param.constructor.identifier !== 'AnyParameter') {
          if (parameterInstanceCount.has(param.constructor.identifier))
            throw new InvalidArgument('Parameters must not have more than one instance supplied');
          else parameterInstanceCount.add(param.constructor.identifier);
        } else {
          if (parameterInstanceCount.has(param.param))
            throw new InvalidArgument('Parameters must not have more than one instance supplied');
          else parameterInstanceCount.add(param.param);
        }

        if (param.constructor.identifier === 'TypeParameter')
          return !/^(?:Related|Tel)Property$/i.test(param.targetProp);

        else if (param.constructor.identifier === 'ValueParameter')
          return param.value === 'text';

        return this.constructor.acceptableParamTypes.has(param.constructor.identifier);
      })
    )
      throw new TypeError('Some of the parameters passed are not valid parameters for AdrProperty');

    else if (
      value.constructor.identifier !== this.constructor.acceptableValTypes ||
      !/^AdrProperty$/i.test(value.targetProp)
    )
      throw new TypeError('Invalid type for value of AdrProperty');
  }

  constructor(params, val) {
    super();

    this.#validate(params, val);
    this.#params = params;
    this.#value = val;

    this.checkAbstractPropertiesAndMethods();
    Object.freeze(this);
  }
}

Object.freeze(AdrProperty);
