import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FileInterface from "../../model/file/FileInterface";
import Modal from 'react-modal';
import './PreviewCarroussel.css'
import { FileTypesEnum } from "../../model/file/FileTypesEnum";
import ReactHlsPlayer from "@gumlet/react-hls-player"
import ReactAudioPlayer from "react-audio-player";


export interface PreviewCarrousselHandler {
  preview: (idx: number, fromPlayButton?: boolean) => void
}

interface PreviewCarrousselProps {
  files: FileInterface[]
}

Modal.setAppElement('#root');

const PreviewCarroussel = forwardRef<PreviewCarrousselHandler, PreviewCarrousselProps>((props, ref) => {
  const files = props.files
  const [isOpen, setOpen] = useState<boolean>(false)
  const [currentIndex, setIndex] = useState<number>(-1)
  const [nextTimeout, setNextTimeout] = useState<ReturnType<typeof setTimeout> | undefined>()
  const [isFromPlayButton, setFromPlayButton] = useState<boolean>(false)

  const videoPreviewUriPrefix = "https://streaming.liberson.app/"
  const videoPreviewUriSuffix = "hls.m3u8"
  const videoPlayerRef = useRef<HTMLVideoElement>(null)

  useImperativeHandle(ref, () => ({
    preview(idx: number, fromPlayButton: boolean = false) {
      setFromPlayButton(fromPlayButton)
      goTo(idx)
    },
  }))


  const cancelTimeout = () => {
    if (nextTimeout) {
      clearTimeout(nextTimeout)
      setNextTimeout(undefined)
    }
  }


  const goTo = (ind: number, closeOnFinish: boolean = false) => {
    cancelTimeout()
    if (isFromPlayButton && closeOnFinish && ind >= files.length) {
      closePreview()
      return
    }
    if (ind <= files.length - 1 && ind >= 0) {
      setIndex(ind)
    }
  }

  useEffect(() => {
    if (currentIndex >= 0) {
      setOpen(true)
      if (![FileTypesEnum.Audio, FileTypesEnum.MP3, FileTypesEnum.Movie].includes(files[currentIndex].type)) {
        const to = setTimeout(() => {
          goTo(currentIndex + 1, true);
        }, 5000);
        setNextTimeout(to)
      }
    }

    // eslint-disable-line react-hooks/exhaustive-deps
  }, [currentIndex, files])

  const closePreview = () => {
    cancelTimeout()
    setIndex(-1)
    setOpen(false)
  }

  const file = files[currentIndex]

  let source = undefined
  if (file) {
    source = file.url;
    if (file.type === FileTypesEnum.Movie) {
      const videoUriPrefix = videoPreviewUriPrefix
      const videoUriSuffix = videoPreviewUriSuffix
      source = videoUriPrefix + source.split("/").pop() + videoUriSuffix
    }
  }

  const download = (url: string) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
        onRequestClose={closePreview}
        style={{ overlay: {}, content: { padding: 0, inset: 0 } }}
      >
        {isOpen && file &&
          <div className="preview-carroussel">
            <div className="preview-header">
              <div className="preview-nav nav-left" onClick={() => { goTo(currentIndex - 1) }}>
                <img src="LeftArrow.svg" alt="" className="nav-image" />
              </div>
              <div className="preview-close">
                <img src="CloseIcon.svg" alt="" className="nav-close-image" onClick={() => { closePreview() }} />
              </div>

              <div className="preview-nav nav-right" onClick={() => { goTo(currentIndex + 1) }}>
                <img src="RightArrow.svg" alt="" className="nav-image" />
              </div>

            </div>
            <div className="preview-body">
              <div className="preview-item" onClick={() => { cancelTimeout() }}>
                {file.type === FileTypesEnum.Image &&
                  <img src={source} className="preview-image" alt="" />
                }
                {file.type === FileTypesEnum.Movie && source &&
                  <ReactHlsPlayer
                    src={source}
                    playerRef={videoPlayerRef}
                    autoPlay={true}
                    controls={true}
                    width="100%"
                    height="auto"
                    style={{ width: '100%', height: '100% ' }}
                    onEnded={() => { goTo(currentIndex + 1) }}
                  />
                }
                {[FileTypesEnum.Doc, FileTypesEnum.Other, FileTypesEnum.Pdf, FileTypesEnum.Audio].includes(file.type) &&
                  <div style={{ height: '50%', width: '70%', textAlign: 'center', }}>
                    {FileTypesEnum.Audio !== file.type &&
                      <div style={{ height: '70%', width: '100%', }} className="preview-item" >
                        {file.type === FileTypesEnum.Pdf &&
                          <img src="pdf.svg" className="preview-icon" alt="" />
                        }
                        {file.type === FileTypesEnum.Doc &&
                          <img src="microsoft-word.svg" className="preview-icon" alt="" />
                        }
                        {file.type === FileTypesEnum.Other &&
                          <img src="other-file.svg" className="preview-icon" alt="" />
                        }
                      </div>
                    }
                    {FileTypesEnum.Audio === file.type &&
                      <>
                        <div style={{ height: '70%', width: '100%', }} className="preview-item" >
                          <img src="AudioIcon.svg" className="preview-icon" alt="" />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: "center" }}>
                          <ReactAudioPlayer
                            src={file.url}
                            autoPlay
                            controls
                            onEnded={() => { goTo(currentIndex + 1) }}
                            style={{ width: '100%', maxWidth: '500px' }}
                          />
                        </div>
                      </>
                    }
                    <div className="file-name" >
                      {decodeURI(file.name)}
                    </div>
                    <div>
                      <button className="download-button" onClick={() => { download(file.url) }}>
                        <div style={{ display: 'flex', justifyContent: "center", }}>
                          <img style={{transform: 'rotate(0deg)'}} src="share-icon.svg" alt="" className="download-icon" />
                          Download
                        </div>
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </Modal>
      {/* 
    <Modal
      isOpen={isOpen}
      onAfterOpen={() => { }}
      onRequestClose={() => { setOpen(false) }}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <h2>Hello</h2>
      <div>I am a modal</div>
      <form>
        <input />
        <button>tab navigation</button>
        <button>stays</button>
        <button>inside</button>
        <button>the modal</button>
      </form>
    </Modal>
    */}
    </>

  )


})

export default PreviewCarroussel