import {AdrPropertyValues} from '../../vcard/buider/Vcard';

export default class AdrBuilderVcf implements AdrPropertyValues {

	postOfficeBox = "";
	extendedAddress = "";
	streetAddress = "";
	locality = "";
	region = "";
	postalCode = "";
	countryName = "";

	constructor(adr: any){
		this.streetAddress = adr['street-address'];
		this.region = adr['region'];
		this.postalCode = adr['postal-code'];
		this.locality = adr['locality'];
		this.countryName = adr['country-name'];
	}




}