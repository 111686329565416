import AdrBuilderVcf from './AdrBuilderVcf';
import {VCardValues} from '../../vcard/buider/Vcard';

export default class CardBuilderVcf implements VCardValues{
	
	fn = "";
	n = {};
	nickname = "";
	adr = new AdrBuilderVcf({});
	email = "";
	phone = "";
	url =[]

	constructor(cardData: any){
		this.fn = cardData['fn'] != undefined && cardData['fn'] !== "" ? cardData['fn'] : "";
		this.n = {};
		if(cardData['adr'] != undefined){
			this.adr = new AdrBuilderVcf(cardData['adr']);
		}
		this.email = cardData['hasEmail'] != undefined && cardData['hasEmail'] !== "" ? cardData['hasEmail'].replace('mailto:', '') : "";
		this.phone = cardData['hasTelephone'] != undefined && cardData['hasTelephone'] !== "" && cardData['hasTelephone']['hasValue'] != undefined ? cardData['hasTelephone']['hasValue'].replace('tel:', '') : "";
		this.url = cardData['url'] != undefined && cardData['url'] !== "" ? cardData['url'] : "";
	}


}