import FileInterFace from './FileInterface';
import {FileTypesEnum} from './FileTypesEnum';

export default class File implements FileInterFace {

	source = "";
	name = "";
	url = "";
	type = FileTypesEnum.Other;
	completed = false;

    constructor(source: string, name: string, type: FileTypesEnum, completed: boolean, url: string){
    	this.source = source;
    	this.type = type;
    	this.name = name;
    	this.completed = completed;
    	this.url = url;
    }
}