import { GeoParameter, LabelParameter } from "../parameters";
import { AdrProperty } from "../properties/index";
import { SpecialValueType } from "../values/SpecialValueType";
import { BaseBuilder } from "./BaseBuilder";
import { AdrPropertyValues } from "./Vcard";
import { VCardBuilder } from "./VCardBuilder";

/**
 * Utility class to specify the components of the delivery address for the vCard object.
 * @see https://www.rfc-editor.org/rfc/rfc6350#section-6.3.1
 */
export class AdrPropertyBuilder extends BaseBuilder {
    private adrPropertyValues: AdrPropertyValues
    private geoParameter?: GeoParameter
    private labelParamenter?: LabelParameter
    private vCardBuilder: VCardBuilder

    constructor(VCardBuilder: VCardBuilder, adrPropertyValues?: AdrPropertyValues) {
        super()
        this.vCardBuilder = VCardBuilder
        this.geoParameter = undefined
        this.labelParamenter = undefined
        if (adrPropertyValues) {
            this.adrPropertyValues = adrPropertyValues
            this.from(adrPropertyValues)
        } else {
            this.adrPropertyValues = {}
        }
    }

    /**
     * Construct from value object
     * @param adrPropertyValues 
     * @returns 
     */
    from(adrPropertyValues: AdrPropertyValues) {
        this.adrPropertyValues = adrPropertyValues
        return this
    }

    /**
     * Set postOfficeBox component
     * To ensure maximal interoperability, their values SHOULD be empty. @see https://www.rfc-editor.org/rfc/rfc6350#section-6.3.1
     * @param postOfficeBox 
     * @returns 
     */
    postOfficeBox(postOfficeBox: string) {
        this.adrPropertyValues.postOfficeBox = postOfficeBox
        return this
    }

    /**
     * Set extendedAddress component
     * To ensure maximal interoperability, their values SHOULD be empty. @see https://www.rfc-editor.org/rfc/rfc6350#section-6.3.1
     * @param extendedAddress (e.g., apartment or suite number);
     * @returns 
     */
    extendedAddress(extendedAddress: string) {
        this.adrPropertyValues.extendedAddress = extendedAddress
        return this
    }

    /**
     * Set streetAddress component
     * @param streetAddress 
     * @returns 
     */
    streetAddress(streetAddress: string) {
        this.adrPropertyValues.streetAddress = streetAddress
        return this
    }

    /**
     * Set locality component
     * @param locality (e.g., city);
     * @returns 
     */
    locality(locality: string) {
        this.adrPropertyValues.locality = locality
        return this
    }

    /**
     * Set region component
     * @param region  (e.g., state or province);
     * @returns 
     */
    region(region: string) {
        this.adrPropertyValues.region = region
        return this
    }

    /**
     * Set postalCode component
     * @param postalCode 
     * @returns 
     */
    postalCode(postalCode: string) {
        this.adrPropertyValues.postalCode = postalCode
        return this
    }

    /**
     * Set countryName component
     * @param countryName 
     * @returns 
     */
    countryName(countryName: string) {
        this.adrPropertyValues.countryName = countryName
        return this
    }

    /**
     * Set the geo parameter
     * @param lat 
     * @param lang 
     * @returns 
     */
    geo(lat: number, lang: number) {
        this.geoParameter = new GeoParameter(`geo:${lat.toFixed(4)},${lang.toFixed(4)}`)
        return this
    }

    /**
     * Set the label parameter
     * @param label 
     * @returns 
     */
    label(label: string) {
        this.labelParamenter = new LabelParameter(this.toTextTypeOrListType(label))
        return this
    }

    buildAdrProperty() {
        let parameters: any[] = []
        if (this.geoParameter) {
            parameters.push(this.geoParameter)
        }
        if (this.labelParamenter) {
            parameters.push(this.labelParamenter)
        }

        this.vCardBuilder.addPropert(
            new AdrProperty(
                parameters,
                new SpecialValueType(
                    [
                        this.toTextTypeOrListType(this.adrPropertyValues.postOfficeBox),
                        this.toTextTypeOrListType(this.adrPropertyValues.extendedAddress),
                        this.toTextTypeOrListType(this.adrPropertyValues.streetAddress),
                        this.toTextTypeOrListType(this.adrPropertyValues.locality),
                        this.toTextTypeOrListType(this.adrPropertyValues.region),
                        this.toTextTypeOrListType(this.adrPropertyValues.postalCode),
                        this.toTextTypeOrListType(this.adrPropertyValues.countryName),
                    ],
                    'AdrProperty'
                )
            ))
        return this.vCardBuilder
    }
}
