import { NProperty } from "../properties/index";
import { SpecialValueType } from "../values/SpecialValueType";
import { BaseBuilder } from "./BaseBuilder";
import { NPropertyValues } from "./Vcard";
import { VCardBuilder } from "./VCardBuilder";


export class NPropertyBuilder extends BaseBuilder {
    private nPropertyValues: NPropertyValues
    private vCardBuilder: VCardBuilder

    constructor(VCardBuilder: VCardBuilder, nPropertyValues?: NPropertyValues) {
        super();
        this.vCardBuilder = VCardBuilder
        if (nPropertyValues) {
            this.nPropertyValues = nPropertyValues
            this.from(nPropertyValues)
        } else {
            this.nPropertyValues = {}
        }
    }

    /**
     * Construct from value object
     * @param nPropertyValues 
     * @returns 
     */
    from(nPropertyValues: NPropertyValues) {
        this.nPropertyValues = nPropertyValues
        return this
    }

    surnames(surnames: string | string[]) {
        this.nPropertyValues.surnames = surnames
        return this
    }

    givenNames(givenNames: string | string[]) {
        this.nPropertyValues.givenNames = givenNames
        return this
    }

    honorificPrefixes(honorificPrefixes: string | string[]) {
        this.nPropertyValues.honorificPrefixes = honorificPrefixes
        return this
    }

    honorificSuffixes(honorificSuffixes: string | string[]) {
        this.nPropertyValues.honorificSuffixes = honorificSuffixes
        return this
    }

    buildNProperty() {
        this.vCardBuilder.addPropert(
            new NProperty(
                [],
                new SpecialValueType(
                    [
                        this.toTextTypeOrListType(this.nPropertyValues.surnames),
                        this.toTextTypeOrListType(this.nPropertyValues.givenNames),
                        this.toTextTypeOrListType(this.nPropertyValues.additionalNames),
                        this.toTextTypeOrListType(this.nPropertyValues.honorificPrefixes),
                        this.toTextTypeOrListType(this.nPropertyValues.honorificSuffixes),
                    ],
                    'nproperty'
                )
            ))
        return this.vCardBuilder
    }
}