//import { AdrProperty, AnniversaryProperty, BdayProperty, BirthPlaceProperty, CaladruriProperty, CaluriProperty, CategoriesProperty, ClientpidmapProperty, ContactURIProperty, DeathDateProperty, DeathPlaceProperty, EmailProperty, ExpertiseProperty, ExtendedProperty, FburlProperty, FNProperty, GenderProperty, GeoProperty, Group, HobbyProperty, IMPPProperty, InterestProperty, KeyProperty, KindProperty, LangProperty, LogoProperty, MemberProperty, NicknameProperty, NoteProperty, NProperty, OrgDirectoryProperty, OrgProperty, PhotoProperty, ProdidProperty, RelatedProperty, RevProperty, RoleProperty, SexType, SoundProperty, SourceProperty, SpecialValueType, TelProperty, TextType, TitleProperty, TzProperty, UIDProperty, URLProperty, VCARD, XMLProperty } from "vcard4";
import { TypeParameter } from "../parameters/TypeParameter";
import { TextListType } from "../values/TextListType";
import { TextType } from "../values/TextType";
import { ValueOrTypedProperty } from "./Vcard";

export class BaseBuilder {
    protected toTextTypeOrListType(value: string | string[] | undefined) {
        if (value) {
            if (Array.isArray(value)) {
                return new TextListType(
                    value.map(v => {
                        new TextType(v)
                    }))
            } else {
                return new TextType(value)
            }
        } else {
            return undefined
        }

    }


    protected getValue(value: ValueOrTypedProperty<string>) {
        return typeof value === 'string' ?
            value :
            value.value
    }

    protected makeTypeParameter(value: ValueOrTypedProperty<string>, propertyName: string) {
        return typeof value === 'string' ?
            [] :
            [
                new TypeParameter(
                    new TextType(value.type),
                    propertyName)
            ]
    }
}